<template>
    <v-container fluid class="fill-height container--fluid" style="width: 100%; height: 100%">
        <v-row>
            <v-col class="deep-orange d-none d-md-flex align-center justify-center" cols="12" lg="7" xl="6">
                <v-container fluid class="fill-height container--fluid">
                    <div class="pa-10">
                        <v-row justify="center">
                            <v-col cols="12" xl="5">
                                <div style="width: 100%">
                                    <h2 class="display-1 white--text font-weight-medium">
                                        Private Equity Baja
                                    </h2>
                                    <h5 class="subtitle-1 mt-4 white--text op-5 font-weight-regular">
                                        <p>
                                            Bienvenidos a Private Equity Baja
                                        </p>
                                    </h5>
                                    <v-btn class="mt-4 text-capitalize"
                                           color="white" outlined x-large
                                           href="https://www.facebook.com/private/"
                                           target="_blank">
                                        Leer más...
                                    </v-btn>
                                </div>
                            </v-col>
                        </v-row>
                    </div>
                </v-container>
            </v-col>
            <v-col class="d-flex align-center" cols="12" lg="5" xl="6">
                <v-container fluid class="fill-height container--fluid">
                    <div class="pa-7 pa-sm-12">
                        <v-row>
                            <v-col cols="12" lg="9" xl="6">
                                <div style="height: 50px"/>
                                <img width="100%" src="@/assets/images/logo-private-black-transparent.png"
                                     alt="logo-private"/>
                                <h2 class="font-weight-bold mt-4 blue-grey--text text--darken-2">Autentifíquese</h2>
                                <h6 v-show="false" class="subtitle-1">
                                    No tiene una cuenta aún?
                                    <a class href="/under-construction">Regístrese</a>
                                </h6>
                                <v-form ref="form" v-model="valid" action="/admin/wireframe" lazy-validation>
                                    <v-text-field
                                        v-model="email"
                                        :rules="emailRules"
                                        class="mt-4"
                                        label="E-mail"
                                        outlined
                                        required
                                    ></v-text-field>
                                    <v-text-field
                                        v-model="password"
                                        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                        :rules="passwordRules"
                                        :type="showPassword ? 'text' : 'password'"
                                        label="Password"
                                        outlined
                                        required
                                        @click:append="showPassword=!showPassword">
                                    </v-text-field>
                                    <v-btn
                                        :disabled="!valid"
                                        block
                                        class="mr-4 white--text"
                                        color="orange"
                                        submit
                                        @click="submit">
                                        Entrar
                                    </v-btn>
                                </v-form>
                                <div class="text-center mt-6">
                                    <!--mdi-home-circle-->
                                    <v-chip class="mr-2" pill @click="goHome">
                                        <v-avatar left>
                                            <v-btn class="white--text" color="secondary">
                                                <v-icon>mdi-home</v-icon>
                                            </v-btn>
                                        </v-avatar>
                                        Ir al Inicio
                                    </v-chip>
                                    <v-chip class="mr-2" pill v-show="false">
                                        <v-avatar left>
                                            <v-btn class="white--text" color="primary lighten-1">
                                                <v-icon>mdi-twitter</v-icon>
                                            </v-btn>
                                        </v-avatar>
                                        Twitter
                                    </v-chip>
                                    <v-chip pill v-show="false">
                                        <v-avatar left>
                                            <v-btn class="white--text" color="primary lighten-1">
                                                <v-icon>mdi-facebook</v-icon>
                                            </v-btn>
                                        </v-avatar>
                                        Facebook
                                    </v-chip>
                                    <div style="height:70px;"></div>
                                </div>
                            </v-col>
                        </v-row>
                    </div>
                </v-container>
            </v-col>
        </v-row>
        <v-row justify="center">
            <v-dialog
                v-model="dialogError"
                max-width="600"
                persistent>
                <v-card>
                    <v-card-title class="text-h6 primary lighten-2">
                        <span class="headline">Error de autentificación</span>
                    </v-card-title>

                    <v-card-text>
                        <div class="text-h6 pa-10">{{ messageError }}</div>
                    </v-card-text>
                    <v-card-actions class="justify-center">
                        <v-btn text @click.stop="closeDialogError">
                            Cerrar
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
        <v-row>
            <v-dialog
                v-model="progressDialog"
                hide-overlay
                persistent
                width="300">
                <v-card
                    color="primary"
                    dark>
                    <v-card-text>
                        Validando las credenciales...
                        <v-progress-linear
                            class="mb-0"
                            color="white"
                            indeterminate
                        ></v-progress-linear>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-row>
    </v-container>
</template>

<script>
import loginService from '@/providers/LoginService';

export default {
    name: "FormLoginComponent",
    data: () => ({
        valid: true,
        showPassword: false,
        progressDialog: false,
        dialogError: false,
        messageError: '',
        email: '',
        checkbox: false,
        emailRules: [
            v => !!v || "El correo electrónico es obligatorio",
            v => /.+@.+\..+/.test(v) || "El correo electrónico debe ser válido"
        ],
        password: '',
        passwordRules: [
            v => !!v || "La contraseña es obligatoria.",
            v => (v || '').length >= 10 || 'La contraseña debe tener al menos de 10 carácteres.'
            // v => (v && v.length >= 10) || "La contraseña debe tener al menos de 10 caracteres"
        ]
    }),
    watch: {
        dialogError(val) {
            val || this.closeDialogError()
        }
    },
    methods: {
        goHome() {
            this.$store.dispatch('clearState');
            this.$router.push({path: "/homepage"});
        },
        submit() {
            this.$refs.form.validate();
            if (this.$refs.form.validate(true)) {
                this.progressDialog = true;
                this.doLogin();
            }
        },
        doLogin() {
            let passport = {
                email: this.email,
                password: this.password
            };
            loginService.loginUser(passport).then(
                response => {
                    // console.log('response: ',response);
                    this.progressDialog = false;
                    this.dialogError = false;
                    if (response.valid) {
                        this.closeDialogError();
                        if (response.active) {
                            if (response.roles !== "ADMINISTRADOR") {
                                this.messageError = "El usuario que intenta autenticar no es un Administrador. "+
                                    "Vuelva a intentar con otro que contenga el rol de Administrador.";
                                this.dialogError = true;
                            } else {
                                loginService.redirectToPage(response.token, 'dashboard');
                            }
                        } else {
                            this.messageError = "El usuario que intenta autenticar se encuentra deshabilitado.";
                            this.dialogError = true;
                        }
                    } else {
                        // console.log('response.valid: ',response.valid);
                        // console.log("messagedData: ", this.messagedData);
                        this.dialogError = true;
                        this.messageError = response.messageText;
                    }
                }
            );
        },
        closeDialogError() {
            this.dialogError = false;
            this.messageError = '';
        }
    }
};
</script>
